var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"title","label":"标题","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"pic","label":"封面","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [(s.row.type === 1)?_c('img',{staticStyle:{"height":"3rem","width":"3rem","border":".5rem"},attrs:{"src":_vm._f("tomedia")(s.row.pic),"alt":""}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"time","label":"时长","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"type","label":"类型","formatter":_vm.typeFormatter,"show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","width":"360"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'shopPluginKnowledgeCourseColumnEdit',
        query:{
          id:s.row.id,
          course_id:_vm.$route.query.id,
        }
        })}}},[_vm._v("编辑 ")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.remove(s.row)}}},[_vm._v("删除")])]}},{key:"header",fn:function(){return [_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'shopPluginKnowledgeCourseColumnEdit',
        query:{
          course_id:_vm.$route.query.id,
        }
        })}}},[_vm._v("添加章节 ")]),_c('el-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回列表")])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }